
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code6 } from "./data";

export default defineComponent({
  name: "size",
  data() {
    return {
      num1: 1,
      num2: 1,
      num3: 1,
      num4: 1
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code6
    };
  }
});
